
import { AxiosRequestConfig } from 'axios'
import { Repository } from 'ecp/repositories/Repository'

import api from 'services/api'

class SimulationECPFRepository extends Repository<{}> {
  async validateMaxSolicitation (config?: AxiosRequestConfig) {
    return Repository.handle(() =>
      this.api.get(`${this.path}/max-solicitation`, config)
    )
  }
}

export default new SimulationECPFRepository({ api, path: '/ecp-funcao/simulation' })
