import { IRoute, RouteGroup } from 'components/Sidebar/types'
import { IUserPermission } from 'egi/repositories/PermissionsRepository'
import { IAuth } from 'egi/types'
import { Permissions, PermissionsEnum } from 'egi/types/IAuth'
import { EcpRoutes } from 'routes/ecp/ecpRoutes'
import { EcpfRoutes } from 'routes/ecpf/ecpfRoutes'

export abstract class UserEcpSidebar {
  abstract filterRoutes(routes: EcpRoutes | EcpfRoutes): (IRoute | RouteGroup)[]

  protected disableRoute = (route: IRoute): IRoute => ({
    ...route,
    meta: {
      ...route.meta,
      disabled: true
    }
  })

  protected changeSearch = (route: IRoute, newSearch: string): IRoute => {
    return {
      ...route,
      meta: {
        ...route.meta,
        location: {
          pathname: route.meta.location?.pathname as string,
          search: newSearch
        }
      }
    }
  }

  protected hideRoute = (route: IRoute): IRoute => ({
    ...route,
    meta: {
      ...route.meta,
      hidden: true
    }
  })

  protected hideGoBackRoute = (route: IRoute): IRoute => ({
    ...route,
    meta: {
      ...route.meta,
      canGoBack: false
    }
  })

  protected changeName = (route: IRoute, name: string): IRoute => ({
    ...route,
    name
  })

  protected permissionRoute = <T extends IRoute>(route: T, permissions: Permissions = [], availablePermission: PermissionsEnum): IRoute => {
    const hasPermission = permissions.find(item => item.value === availablePermission)

    return {
      ...route,
      meta: {
        ...route.meta,
        hidden: !hasPermission
      }
    }
  }
}

export abstract class UserEcpfSidebar extends UserEcpSidebar {
  protected permissions: IUserPermission[]

  constructor (permissions: IUserPermission[]) {
    super()

    this.permissions = permissions
  }
}

interface IEcpSidebar {
  userRoutes: UserEcpSidebar
}

export default class EcpSidebar {
  protected userRoutes: UserEcpSidebar

  constructor ({ userRoutes }: IEcpSidebar) {
    this.userRoutes = userRoutes
  }

  getUserRoutes (routes: EcpRoutes) {
    return this.userRoutes.filterRoutes(routes)
  }
}

class AgxRoutesEcp extends UserEcpSidebar {
  filterRoutes (routes: EcpRoutes) {
    return Object.values(routes)
  }
}

interface IMasterRoutes extends UserEcpSidebar {
  masterRoutes: (routes: EcpRoutes) => IRoute[]
  supportRoutes: (routes: EcpRoutes) => IRoute[]
}
class MasterRoutesEcp extends UserEcpSidebar implements IMasterRoutes {
  protected auth: IAuth

  constructor (auth: IAuth) {
    super()

    this.auth = auth
  }

  supportRoutes (routes: EcpRoutes) {
    return [
      routes.ecpProposalMenu as unknown as IRoute,
      routes.ecpProposalDetails,
      routes.collaborators,
      routes.teamPromoterIn,
      routes.ecpRh,
      routes.ecpRHDetails,
      routes.ecpJuridical,
      routes.profile,
      routes.ecpConfiguration,
      routes.ecpReports,
      routes.chat,
      routes.ecpOnidataLogs,
      routes.ecpOnidataLogsDetails,
      this.hideRoute(routes.ecpLogsAPI),
      routes.ecpSecurityLogs,
      routes.ecpLogsMenu,
      routes.ecpLogsOnidataQueues,
      this.permissionRoute(routes.ecpAccessManager as unknown as IRoute, this.auth.permissions, PermissionsEnum.gestordeAcesso),
      this.hideRoute(this.permissionRoute(routes.accessManagerDetails, this.auth.permissions, PermissionsEnum.gestordeAcesso)),
      routes.suspectBehavior,
      routes.suspectBehaviorWhiteList,
      routes.suspectBehaviorBanList
    ]
  }

  masterRoutes (routes: EcpRoutes) {
    return [
      routes.ecpProposalMenu as unknown as IRoute,
      routes.ecpProposalDetails,
      routes.collaborators,
      routes.teamPromoterIn,
      routes.ecpRh,
      routes.ecpRHDetails,
      routes.ecpJuridical,
      routes.profile,
      this.hideGoBackRoute(routes.ecpLogsAPI),
      routes.ecpConfiguration,
      routes.ecpReports,
      routes.chat,
      this.permissionRoute(routes.ecpAccessManager as unknown as IRoute, this.auth.permissions, PermissionsEnum.gestordeAcesso),
      this.hideRoute(this.permissionRoute(routes.accessManagerDetails, this.auth.permissions, PermissionsEnum.gestordeAcesso))
    ]
  }

  onidataConfigRoutes (routes: EcpRoutes) {
    return [
      routes.ecpOnidataCredentialsConfiguration
    ]
  }

  filterRoutes (routes: EcpRoutes) {
    if (!this.auth?.helpdesk) return this.masterRoutes(routes)
    if (this.auth.isConfigUser) return this.onidataConfigRoutes(routes)
    return this.supportRoutes(routes)
  }
}

interface IPromoterEcpSidebar extends UserEcpSidebar {
  CBRoutes: (routes: EcpRoutes) => IRoute[]
  INRoutes: (routes: EcpRoutes) => IRoute[]
  TMRoutes: (routes: EcpRoutes) => IRoute[]
}

class PromoterRoutesEcp extends UserEcpSidebar implements IPromoterEcpSidebar {
  protected auth: IAuth

  constructor (auth: IAuth) {
    super()
    this.auth = auth
  }

  CBRoutes (routes: EcpRoutes) {
    return [

      routes.ecpProposalMenu as unknown as IRoute,

      routes.ecpProposalDetails,

      routes.profile,

      routes.ecpPortabilityAccept,
      routes.ecpReports

    ]
  }

  INRoutes () {
    return []
  }

  TMRoutes () {
    return []
  }

  filterRoutes (routes: EcpRoutes) {
    if (this.auth?.type === 'CB') return this.CBRoutes(routes)
    return []
  }
}

class ClientRoutesEcp extends UserEcpSidebar {
  protected auth: IAuth

  constructor (auth: IAuth) {
    super()

    this.auth = auth
  }

  allRoutes (routes: EcpRoutes) {
    return [

      routes.ecpParcelAntecipationList,
      routes.ecpAcceptCredit,

      routes.ecpPortabilityAccept,

      routes.ecpProposalList,
      routes.ecpProposalListSearch,
      routes.ecpProposalDetails,
      routes.ecpLGPD,

      routes.ecpScoreForm,

      routes.profile,
      routes.ecpReports,
      this.disableRoute(routes.ecpScore)
    ]
  }

  withoutCreationProposalRoutes (routes: EcpRoutes) {
    return [
      routes.ecpAcceptCredit,

      routes.ecpProposalMenu as unknown as IRoute,

      routes.ecpProposalDetails,
      routes.ecpLGPD,
      routes.ecpScoreForm,

      routes.profile,
      routes.ecpReports,
      this.disableRoute(routes.ecpScore)
    ]
  }

  filterRoutes (routes: EcpRoutes) {
    if (this.auth.canCreateEcpProposal) {
      return this.allRoutes(routes)
    }

    return this.withoutCreationProposalRoutes(routes)
  }
}

class BackofficeRoutesEcp extends UserEcpSidebar {
  protected auth: IAuth

  constructor (auth: IAuth) {
    super()
    this.auth = auth
  }

  creditRoutes (routes: EcpRoutes) {
    return [
      routes.ecpProposalMenu as unknown as IRoute,
      routes.ecpProposalDetails,
      routes.profile,
      routes.chat
    ]
  }

  juridicoRoutes (routes: EcpRoutes) {
    return [

      routes.ecpProposalMenu as unknown as IRoute,
      routes.ecpProposalDetails,

      routes.profile,
      routes.chat
    ]
  }

  filterRoutes (routes: EcpRoutes) {
    switch (this.auth.areaName) {
      case 'credito':
        return this.creditRoutes(routes)
      case 'juridico':
        return this.juridicoRoutes(routes)
      default:
        return []
    }
  }
}

class RhRoutesEcp extends UserEcpSidebar {
  updateRoutesNameForClient (route: IRoute) {
    return {
      ...route,
      name: route.name.replace(/Cliente/gi, 'Funcionário')
    }
  }

  filterRoutes (routes: EcpRoutes) {
    return [
      routes.ecpRHEndorsementMenu,
      routes.ecpRHDetails,
      routes.ecpProposalDetails,
      this.hideRoute(routes.ecpProposalList),
      routes.profile,
      routes.ecpRHTransfersMenu,
      routes.ecpRHTransfers,
      routes.ecpRHTransfersDetailing,

      routes.ecpReports,
      routes.ecpRHCreate,
      routes.ecpRHEdit,
      routes.ecpRh
    ]
  }
}

class CommissionRoutesEcp extends UserEcpSidebar {
  filterRoutes () {
    return []
  }
}

class ComercialRoutesEcp extends UserEcpSidebar {
  filterRoutes () {
    return []
  }
}

class PricingRoutesEcp extends UserEcpSidebar {
  filterRoutes () {
    return []
  }
}

class AdiministratorRoutesEcp extends UserEcpSidebar {
  protected auth: IAuth

  constructor (auth: IAuth) {
    super()
    this.auth = auth
  }

  creditRoutes (routes: EcpRoutes) {
    return [
      routes.ecpProposalMenu as unknown as IRoute,
      routes.ecpProposalDetails,
      routes.profile,
      routes.chat
    ]
  }

  juridicoRoutes (routes: EcpRoutes) {
    return [

      routes.ecpProposalDetails,
      routes.ecpJuridicalProposalMenu,
      routes.profile,
      routes.chat
    ]
  }

  filterRoutes (routes: EcpRoutes) {
    switch (this.auth.areaName) {
      case 'credito':
        return this.creditRoutes(routes)
      case 'juridico':
        return this.juridicoRoutes(routes)
      default:
        return []
    }
  }
}

class ConsultantRoutesEcp extends UserEcpSidebar {
  filterRoutes () {
    return []
  }
}

class ProviderRoutesEcp extends UserEcpSidebar {
  filterRoutes () {
    return []
  }
}

class SellerRoutesEcp extends UserEcpSidebar {
  filterRoutes () {
    return []
  }
}

class AuditorRoutesEcp extends UserEcpSidebar {
  filterRoutes () {
    return []
  }
}

class OperatorRoutesEcp extends UserEcpSidebar {
  filterRoutes (routes: EcpRoutes) {
    return [

      routes.ecpProposalMenu as unknown as IRoute,
      routes.ecpProposalDetails,

      routes.ecpPortabilityAccept,

      routes.ecpReports,
      routes.profile
    ]
  }
}

export {
  AgxRoutesEcp,
  MasterRoutesEcp,
  PromoterRoutesEcp,
  ClientRoutesEcp,
  BackofficeRoutesEcp,
  CommissionRoutesEcp,
  ComercialRoutesEcp,
  PricingRoutesEcp,
  AdiministratorRoutesEcp,
  ConsultantRoutesEcp,
  ProviderRoutesEcp,
  SellerRoutesEcp,
  RhRoutesEcp,
  AuditorRoutesEcp,
  OperatorRoutesEcp
}
