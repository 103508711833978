import { FormInstance } from 'antd/lib/form'
import { hasFilterCounter } from 'components/ServerTable/function'
import { ISearch } from 'components/SeverTable/types'
import dayjs, { Dayjs } from 'dayjs'
import { timeAsDayjs } from 'utils/time'
import { formatFieldsForm } from 'utils/utils'

export const logsFiltersKeysIgnore = [
  'search',
  'page',
  'limit',
  'sort',
  'startDate',
  'endDate',
  'identifier'
]

export const logsFiltersEntriesToIgnore: {}[] = [
  { method: undefined }
]
const currentDate = dayjs()
const startOfMonth = currentDate.startOf('month')
const dynamicDate = startOfMonth.format('YYYY-MM-DD')
export const logInitialDate = timeAsDayjs(dynamicDate, { applyTimezone: false })

export const generateLogDateValues = (startDate?: string, endDate?: string): [Dayjs, Dayjs] => [
  timeAsDayjs(startDate || logInitialDate, { applyTimezone: false }),
  timeAsDayjs(endDate || dayjs(), { applyTimezone: false })
]

export const onidataLogsIdentifiers = [
  { label: 'Onidata ID', value: 'onidataId' },
  { label: 'Line ID', value: 'lineId' }
]

export const filterCounter = (form: FormInstance<any>, filters: ISearch) => {
  form.setFieldsValue(formatFieldsForm(filters))

  return Object
    .entries(filters)
    .filter(hasFilterCounter(logsFiltersKeysIgnore, logsFiltersEntriesToIgnore))
    .length
}

export const LOGS_INITIAL_FILTERS = {
  page: 1,
  limit: 15,
  startDate: logInitialDate.format('YYYY-MM-DD'),
  endDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')
}

export const CLIENTS_FILTERS_ENTRIES_TO_IGNORE: {}[] = [
  { possui_acesso: undefined },
  { status: [] }
]

export const CLIENTS_FILTERS_KEYS_IGNORE = [
  'search',
  'page',
  'limit',
  'sort',
  'identifier'
]
