import { useState } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import DynamicProposalECPFRepository from 'ecpf/repositories/DynamicProposalECPFRepository'
import { IReasons } from 'ecp/app/Proposals/proposalInterfaces'

const formatToOptions = (item: IReasons) => ({
  value: item._id,
  label: item.reason
})

export default function useProposalReasonsOptions () {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [reasons, setReasons] = useState<{ value: string, label: string }[]>([])

  async function onFetchReasons (proposalInfo: {status: string, stepId: string, proposalId?: string}, abortController: AbortController) {
    const { proposalId, status, stepId } = proposalInfo
    try {
      setLoading(true)

      const requestConfig: AxiosRequestConfig = {
        signal: abortController.signal,
        params: { proposalId, status, stepId }
      }

      const response = await DynamicProposalECPFRepository.getReasons(requestConfig)
      const reasons = response.data.data?.reasons

      if (!reasons?.length) {
        setReasons([])
        throw new Error('Nenhum motivo encontrado')
      }

      const formatedValues = (reasons || []).map(formatToOptions)
      setReasons(formatedValues)
      setLoading(false)
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        setErrorMessage(err?.message)
        setLoading(false)
      }
      setReasons([])
    }
  }

  return { loading, errorMessage, reasons, onFetchReasons }
}
