import { ICovenantProposal } from 'ecp/repositories/CovenantRepository'
import { IProduct, _productSlugs } from 'ecp/models/ProductModel'
import { _typePromoterEnum, _userLevel } from 'ecp/models/UsersModel'
import { AuthorizationsUser } from 'ecp/repositories/LoginRepository'
import { RouterDomPath } from 'store/modules/auth/authTypes'
import { _levelNameEnum, _tableAreas, _promoterChannelEnum } from 'globals'
import IBank from './IBank'
import { IRequestLGPDResponse } from 'ecp/app/LGPD/LGPDInterfaces'

export enum PermissionsEnum {
  gestordeAcesso='gestordeAcesso'
}

export type Permissions = Array<{label: string, value: PermissionsEnum}>

interface IAuth {
  _id: string
  mainId?: string
  isAuth?: boolean
  covenantId: string
  email?: string
  cellphone?: string
  convenio: Partial<ICovenantProposal>
  personType?: string
  cpf?: string
  cnpj?: string
  name?: string
  bank?: Partial<IBank>
  gender: string
  emailVerified?: boolean
  selfie?: string
  fantasyName?: string
  foundationDate?: string
  documentId?: string
  pix?: {
    type: string
    value: string
  }
  level: _userLevel
  levels: AuthorizationsUser[]
  promoterId?: string
  administratorId?: string
  type?: _typePromoterEnum
  expiresIn?: number
  token?: string
  refreshToken?: string
  sessionTimeout?: string
  expiresAt?: string | Date

  proposalId?: string

  areas?: Array<string>

  birthdate?: Date
  status?: string
  levelName?: _levelNameEnum
  areaName?: _tableAreas

  helpdesk?: boolean
  channel?: _promoterChannelEnum
  onidataId?: string
  routerDomPaths: RouterDomPath[]
  selectedProduct: Partial<IProduct>
  products: Array<_productSlugs>
  followUpProposal: boolean
  canSeeCorbanSelect?: boolean
  inviteAdmin?: boolean

  canCreateEcpProposal?: boolean
  communicationVisible: boolean

  isConfigUser: boolean
  lgpd?: IRequestLGPDResponse
  permissions?: Permissions
}

export default IAuth
