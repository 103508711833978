import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox'
import IframePDF from 'ecp/components/IframePDF/IframePDF'
import TermsRepository from 'ecp/repositories/TermsRepository'
import React, { useEffect, useState } from 'react'
import { Button, Form, message, Tag } from 'antd'
import { IRequestLGPDResponse } from './LGPDInterfaces'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import useAuthLayout from 'hooks/useAuthLayout'
import { useHistory } from 'react-router-dom'

export function LgpdTextRedirect ({ href }: { href?: string | null }) {
  return (
    <>
      Li e concordo com os <a target='_blank'
        rel='noopener noreferrer'
        href={href || undefined}
      ><strong>Termos e Condições de uso e LGDP</strong></a>
    </>
  )
}

interface Props {
  layoutPage?: boolean
  docUrl: string | null | undefined
}

function LGPD ({ layoutPage = false, docUrl = '' }: Props) {
  const history = useHistory()

  const { handleReset, handleLGPDTermSignature, handleLoading } = useAuthLayout()
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [termsCheckbox, setTermsCheckbox] = useState(false)
  const [terms, setTerms] = useState<IRequestLGPDResponse>()

  const isButtonDisabled = termsCheckbox === false

  async function getTerms () {
    setLoading(true)
    try {
      const response = await TermsRepository.getTerms()
      const terms = response.data.data

      if (terms) setTerms(terms)
      setLoading(false)
    } catch (err) {
      if (err?.message) message.error(err.message)
      setLoading(false)
    }
  }

  const onSubmit = async () => {
    try {
      setSubmitLoading(true)
      const response = await TermsRepository.accept({
        type: 'funcionario'
      })

      message.success(response.data?.message)
      if (layoutPage) {
        handleLoading(true)
        await handleLGPDTermSignature()
        handleLoading(false)
      } else {
        getTerms()
      }
      setSubmitLoading(false)
    } catch (error) {
      if (error?.message) message.error(error.message)
      setLoading(false)
      setSubmitLoading(false)
    }
  }

  const handleLogout = () => {
    handleReset()
    return history.push('/')
  }

  const onTermsChange = (event: CheckboxChangeEvent) => {
    setTermsCheckbox(event.target.checked)
  }

  useEffect(() => {
    if (!layoutPage) getTerms()
  }, [])

  const iframePdf = (
    <IframePDF
      className='ecp-lgpd__pdf'
      url={terms?.url || docUrl || null}
      loading={loading}
      onLoad={() => setLoading(false)}
    />
  )

  if (loading) {
    return (
      <div className='ecp-lgpd text-center'>
        <LoadingOutlined />
      </div>
    )
  }

  if (terms?.assinado_em) {
    return (
      <div className='ecp-lgpd'>
        <div className='mb-3'>
          {iframePdf}
        </div>

        <h4 className='ecp-lgpd__signature-title'>Informações da assinatura:</h4>
        <p><Tag color='success'>Assinado</Tag> Hora do aceite: <b>{dayjs(terms?.assinado_em || '').format('DD/MM/YYYY HH:mm:ss')}</b></p>
      </div>
    )
  }

  return (
    <div className='ecp-lgpd'>
      <Form
        layout='vertical'
        onFinish={onSubmit}
      >
        {iframePdf}

        <Form.Item
          name='lgpd'
          valuePropName='checked'
          className='my-2'
        >
          <Checkbox onChange={onTermsChange}>
            <LgpdTextRedirect href={terms?.url} />
          </Checkbox>
        </Form.Item>

        <div className='ecp-lgpd__button-container'>
          {layoutPage && (
            <Button className='ecp-lgpd__button--logout' onClick={handleLogout}>
              Sair do sistema
            </Button>
          )}

          <Button
            className='ecp-lgpd__button'
            loading={submitLoading}
            type='primary'
            disabled={isButtonDisabled}
            htmlType='submit'
          >
            Enviar
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default LGPD
