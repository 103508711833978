import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/modules/rootReducer'
import { progressQueuestActions } from 'store/modules/progressQueue/progressQueueActions'
import { IProgressQueueActions, IProgressQueueItem, IStatusProgressQueue } from 'store/modules/progressQueue/progressQueueReducer'
import { useCallback } from 'react'
import { tablesUpdate } from 'store/modules/tables/actions'
import { ECP } from 'routes/ecp/constants'
import EvaluateRepository from 'ecp/repositories/EvaluateRepository'
import { IProgressQueueInfo, IProgressQueueInfoStatus, IProgressQueueListType } from 'ecp/components/ProgressQueue/progressQueueInterfaces'
import ReportsRepository from 'ecp/repositories/ReportsRepository'

function getItemPathCLientLGPD ({ approval, status } :{approval: boolean, status: IProgressQueueInfoStatus, id: string}) {
  if (status === 'finish' && approval) return '/auth'
  return undefined
}

function getItemPath (itemType: IProgressQueueListType, item: IProgressQueueInfo) {
  const { itemId, approval, status, onidataId } = item
  switch (itemType) {
    case 'pendingUser':
      return getItemPathCLientLGPD({ approval: approval || false, status, id: onidataId || '' })
    case 'proposal':
      return `/auth${ECP.PROPOSAL_DETAILS.path.replace(':proposalId', itemId || '')}`
    default:
      return undefined
  }
}

function getItemText (itemType: IProgressQueueListType) {
  switch (itemType) {
    case 'pendingUser':
      return 'Clique para Visualizar detalhes'
    case 'proposal':
      return 'Clique para visualizar a proposta'
    default:
      return undefined
  }
}

function succesMessage (itemType: IProgressQueueListType, approval: boolean, status?: IProgressQueueInfoStatus) {
  switch (itemType) {
    case 'pendingUser':
      if (status === 'error') return ''
      return `Cliente ${approval ? 'Aprovado' : 'Removido'} com sucesso!`
    case 'proposal':
      return 'Proposta averbada com sucesso!'
    case 'report':
      return 'Relatório gerado com sucesso!'
    default:
      return 'Sucesso'
  }
}

function itemInfoText (itemType: IProgressQueueListType, item: IProgressQueueInfo) {
  switch (itemType) {
    case 'pendingUser':
      return ''
    case 'proposal':
      return `${item.onidataId}`
    default:
      return ''
  }
}

const formatProgressQueueInfoList = (list: IProgressQueueInfo[]) => {
  const formatedProgressQueueInfoList = list.map(item => {
    return {
      ...item,
      link: {
        path: getItemPath(item.type, item),
        text: getItemText(item.type)
      },
      succesMessage: succesMessage(item.type, item.approval || false, item.status),
      info: itemInfoText(item.type, item)
    }
  })
  return formatedProgressQueueInfoList as IProgressQueueInfo[]
}

export default function useProgressQueue () {
  const dispatch = useDispatch()
  const progressQueue = useSelector((state: RootState) => state.progressQueue)

  const setProgressQueueIsImporting = (isImporting: boolean, itemIndex: number) => {
    dispatch(progressQueuestActions.seIsImporting({ isImporting, itemIndex }))
  }

  const setProgressQueueId = (progressCheckId: string, itemIndex: number) => {
    dispatch(progressQueuestActions.setProgressCheckId({ progressCheckId, itemIndex }))
  }

  const setProgressQueueListLenght = (listLenght: number, itemIndex: number) => {
    dispatch(progressQueuestActions.setListLenght({ listLenght, itemIndex }))
  }

  const setProgressQueueList = (list: string[], itemIndex: number) => {
    dispatch(progressQueuestActions.setList({ list, itemIndex }))
  }

  const setProgressQueueOpenCard = (isOpen: boolean, itemIndex: number) => {
    dispatch(progressQueuestActions.setIsOpen({ isOpen, itemIndex }))
  }

  const setProgressQueueInfoList = (progressQueueInfoList: IProgressQueueInfo[], itemIndex: number) => {
    dispatch(progressQueuestActions.setProgressQueueInfoList({ progressQueueInfoList, itemIndex }))
  }

  const setPercentage = (percentage: number, itemIndex: number) => {
    dispatch(progressQueuestActions.setPercentage({ percentage, itemIndex }))
  }

  const setProgressQueueStatus = (status: IStatusProgressQueue, itemIndex: number) => {
    dispatch(progressQueuestActions.setStatus({ status, itemIndex }))
  }

  const setProgressQueueAction = (action: IProgressQueueActions, itemIndex: number) => {
    dispatch(progressQueuestActions.setAction({ action, itemIndex }))
  }

  const addProgressQueueItens = (item: IProgressQueueItem, queueList: IProgressQueueItem[]) => {
    queueList.push(item)
    dispatch(progressQueuestActions.setQueueList({ queueList }))
  }

  const removeProgressQueueItem = (queueList: IProgressQueueItem[], itemIndex: number) => {
    queueList.splice(itemIndex, 1)
    dispatch(progressQueuestActions.reset())
    dispatch(progressQueuestActions.setQueueList({ queueList }))
  }

  const progressQueueProgressCheck = useCallback(async (abortController: AbortController, progressCheckId: string, itemIndex: number) => {
    try {
      const requestConfig = {
        signal: abortController.signal
      }
      const response = await EvaluateRepository.progressQueueProgress(progressCheckId, requestConfig)
      if (response.data.data?.percentage) setPercentage(response.data.data?.percentage, itemIndex)

      if (response.data.data && response.data.data.percentage === 100) {
        if (response.data.data?.list) {
          const formatedProgressQueueInfoList = formatProgressQueueInfoList(response.data.data?.list)
          setProgressQueueInfoList(formatedProgressQueueInfoList, itemIndex)
        }

        setProgressQueueListLenght(0, itemIndex)
        setProgressQueueList([], itemIndex)
        setProgressQueueIsImporting(false, itemIndex)
        setProgressQueueId('', itemIndex)
        setProgressQueueStatus('success', itemIndex)
        dispatch(tablesUpdate())
      }
    } catch (err) {
      if (!abortController.signal.aborted) {
        setProgressQueueIsImporting(false, itemIndex)
      }
    }
  }, [])

  const progressQueueProgressCheckReports = useCallback(async (abortController: AbortController, progressCheckId: string, itemIndex: number) => {
    try {
      const requestConfig = {
        signal: abortController.signal
      }

      const response = await ReportsRepository.queueProgress(progressCheckId, requestConfig)
      if (response.data.data?.queueCheck.percentage) setPercentage(response.data.data?.queueCheck.percentage, itemIndex)

      if (response.data.data && response.data.data.queueCheck.percentage === 100) {
        const { link, error, status } = response.data.data.queueCheck
        const listInfo = []
        const info = {
          succesMessage: error ?? response.data.message,
          info: '',
          type: 'report' as IProgressQueueListType,
          link: {
            path: link,
            text: 'Link para download'
          },
          status: status ?? 'finish' as IProgressQueueInfoStatus
        }

        listInfo.push(info)

        setProgressQueueInfoList(listInfo, itemIndex)
        setProgressQueueListLenght(0, itemIndex)
        setProgressQueueList([], itemIndex)
        setProgressQueueIsImporting(false, itemIndex)
        setProgressQueueId('', itemIndex)
        setProgressQueueStatus('success', itemIndex)
        dispatch(tablesUpdate())
      }
    } catch (err) {
      if (!abortController.signal.aborted) {
        setProgressQueueIsImporting(false, itemIndex)
      }
    }
  }, [])

  const resetProgressQueue = () => {
    dispatch(progressQueuestActions.reset())
  }

  return {
    progressQueue,
    setProgressQueueIsImporting,
    setProgressQueueId,
    setProgressQueueListLenght,
    setProgressQueueList,
    setProgressQueueOpenCard,
    setProgressQueueInfoList,
    setPercentage,
    resetProgressQueue,
    setProgressQueueStatus,
    progressQueueProgressCheck,
    setProgressQueueAction,
    addProgressQueueItens,
    removeProgressQueueItem,
    progressQueueProgressCheckReports
  }
}
