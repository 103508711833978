import { Col, Empty, Form, Input, Row, Select, Spin } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { _reviewStatusOption } from 'components/ReviewBackoffice/reviewBackofficeInterfaces'
import RichTextEditor from 'components/RichTextEditor/RichTextEditor'
import ProposalStatus from 'ecp/components/ProposalStatus/ProposalStatus'
import ProposalModel from 'ecp/models/ProposalModel'
import { StepSectionTitle } from 'egi/app/ProposalEgi/components/StepRenders/StepRenders'
import React, { useEffect, useState } from 'react'
import { IDynamicProposalStep } from '../../proposalInterfaces'
import useProposalReasonsOptions from 'ecpf/app/ProposalECPF/hooks/useProposalReasonsOptions'

type IProps = {
  errors: any
  onEditorChange(value: string): void
  editorValue: string
  step: IDynamicProposalStep
  form: FormInstance
  clientName?: string
}

function ExtraInputsWrapper ({ children, conditional }: { children: React.ReactNode, conditional?: boolean }) {
  if (!conditional) return <></>

  return <>{children}</>
}

function ReasonsLoadingWrapper ({ children, loading }: { children: React.ReactNode, loading: boolean }) {
  if (loading) {
    return <div className='mb-2'><Spin /></div>
  }

  return <>{children}</>
}

export interface IProposalReviewForm {
  status: string
  reasonId: string
  description: string
}

function ProposalReview ({ errors, onEditorChange, editorValue, step, form, clientName }: IProps) {
  const [selectedStatus, setSeletecStatus] = useState< _reviewStatusOption>()

  const { loading: loadinReasons, errorMessage, reasons, onFetchReasons } = useProposalReasonsOptions()

  const onChangeRadio = (value: _reviewStatusOption) => {
    setSeletecStatus(value)
    form.setFieldsValue({ status: value, reasonId: null })
  }

  useEffect(() => {
    const abortController = new AbortController()
    if (selectedStatus) {
      onFetchReasons({ status: selectedStatus, stepId: step._id }, abortController)
    }

    return () => abortController.abort()
  }, [selectedStatus])

  return (
    <div className='proposal-review'>
      <StepSectionTitle title="Adicionar nova revisão"/>

      <Row gutter={15}>
        <Col span={24}>
          <Form.Item
            name='status'
            help={errors?.status && errors?.status}
            validateStatus={errors?.status && 'error'}
            rules={[{ required: true, message: 'Escolha o status' }]}
            label='Novo status'
          >
            <ProposalStatus
              stepSlug={step.slug}
              onChangeRadio={onChangeRadio}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={15}>
        <ExtraInputsWrapper conditional={selectedStatus === 'remaking'}>
          <Col lg={24} sm={24} xs={24}>
            <Form.Item
              name='instructions'
              help={errors?.status && errors?.status}
              validateStatus={errors?.status && 'error'}
              label={<>Instruções para o reenvio do cliente: <b>&nbsp;{clientName}</b></>}
            >
              <Input placeholder='Digite as instruções para seu cliente fazer o reenvio do passo'/>
            </Form.Item>
          </Col>
        </ExtraInputsWrapper>

        <ReasonsLoadingWrapper loading={loadinReasons}>
          {((reasons && reasons.length > 0) || loadinReasons) && (
            <ExtraInputsWrapper conditional={ProposalModel.showReasonSelectInReview(step.slug)}>
              <Col lg={12} sm={24} xs={24}>
                <Form.Item
                  help={errors?.reasonId && errors?.reasonId}
                  validateStatus={errors?.reasonId && 'error'}
                  label={<span>Motivo</span>}
                  name='reasonId'
                >
                  <Select
                    loading={loadinReasons}
                    placeholder='Selecione um motivo para a revisão...'
                    options={reasons || []}
                    notFoundContent={
                      errorMessage && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={errorMessage || ''} />
                    }
                    disabled={loadinReasons}
                  />
                </Form.Item>
              </Col>
            </ExtraInputsWrapper>
          )}
        </ReasonsLoadingWrapper>

        <Col lg={24} sm={24} xs={24}>
          <Form.Item
            help={errors?.description && errors?.description}
            validateStatus={errors?.description && 'error'}
            label={<span>Descrição</span>}
            className='review__rich'
            name='description'
          >
            <RichTextEditor
              onChange={onEditorChange}
              setContents={editorValue}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default ProposalReview
