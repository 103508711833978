import {
  AuditOutlined, DollarCircleOutlined,
  DollarOutlined, FileSyncOutlined,
  HistoryOutlined, PartitionOutlined, RetweetOutlined,
  SettingOutlined, SolutionOutlined, TeamOutlined,
  UserSwitchOutlined
} from '@ant-design/icons'
import { IRoute } from 'components/Sidebar/types'
import { LogErrorsList, LogsMenu, LogsOnidata, LogsOnidataDetails, LogsOnidataQueues, SecurityLogs } from 'ecp/app/Logs/LogsLoader'
import {
  Portability,
  PortabilityAccept, PortabilityCreate,
  PortabilityFindUserCovenantSelect, PortabilityFindUserCPF,
  PortabilityFindUserInformationConfirm
} from 'ecp/app/Portability/PortabilityLoader'
import {
  AcceptContract, ProposalClientList, ProposalCreate, ProposalDetails,
  ProposalFindUserCovenantSelect, ProposalFindUserCPF,
  ProposalFindUserInformationConfirm, ProposalList,
  ProposalListSearch, Score,
  ScoreForm
} from 'ecp/app/Proposals/ProposalsLoader'
import {
  RenegotiationContracts,
  RenegotiationCreate,
  RenegotiationFindUserCovenantSelect,
  RenegotiationFindUserCPF, RenegotiationFindUserInformationConfirm
} from 'ecp/app/Renegotiation/RenegotiationLoader'
import {
  RHDetails, RHForm, RHList,
  RHTransferDetailing,
  RHTransferPrevAllotmentSelect, RHTransfersController,
  RHTransfersControllerSelect
} from 'ecp/app/RH/RHLoader'
import BanList from 'ecp/app/SuspectBehavior/views/BanList/BanList'
import SuspectBehaviorMenu from 'ecp/app/SuspectBehavior/views/SuspectBehaviorMenu/SuspectBehaviorMenu'
import WhiteList from 'ecp/app/SuspectBehavior/views/WhiteList/WhiteList'
import AccessManagerBackOffice from 'egi/app/AccessManager/views/AccessManagerBackOffice/AccessManagerBackOffice'
import AccessManagerBackOfficeDetails from 'egi/app/AccessManager/views/AccessManagerBackOfficeDetails/AccessManagerBackOfficeDetails'
import AccessManagerClient from 'egi/app/AccessManager/views/AccessManagerClient/AccessManagerClient'
import { lazy } from 'react'
import { portabilityDisabled, refinDisabled } from '../disableds'
import { chat, collaborators, listMaster, profile, teamPromoterIn } from '../routes'
import { ECP } from './constants'

const ParcelsAntecipationList = lazy(() => import('ecp/app/ParcelsAntecipation/ParcelsAntecipationList' /* webpackChunkName: "ParcelsAntecipation" */))
const LGPD = lazy(() => import('ecp/app/LGPD/LGPD' /* webpackChunkName: "LGPD" */))
const Juridical = lazy(() => import('ecp/app/Juridical/views/JuridicalList/JuridicalList' /* webpackChunkName: "Juridical" */))
const ReportsList = lazy(() => import('ecp/app/Reports/views/Reports' /* webpackChunkName: "Reports" */))
const Configuration = lazy(() => import('ecp/app/Configuration/views/Configuration/Configuration' /* webpackChunkName: "Configuration" */))
const OnidataCredentialsConfig = lazy(() => import('ecp/app/OnidataCredentialsConfig/views/OnidataCredentialsConfig' /* webpackChunkName: "OnidataCredentialsConfig" */))

const ecpClientProposalList: IRoute = {
  path: ECP.CLIENT_PROPOSAL_LIST.path,
  name: ECP.CLIENT_PROPOSAL_LIST.name,
  component: ProposalClientList,
  icon: SolutionOutlined,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpLGPD: IRoute = {
  path: ECP.LGPD.path,
  name: ECP.LGPD.name,
  icon: SolutionOutlined,
  component: LGPD,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpLogsAPI: IRoute = {
  path: ECP.LOGS_API.path,
  name: ECP.LOGS_API.name,
  icon: SolutionOutlined,
  component: LogErrorsList,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: true
  }
}

const ecpOnidataLogs: IRoute = {
  path: ECP.ONIDATA_LOGS.path,
  name: ECP.ONIDATA_LOGS.name,
  icon: HistoryOutlined,
  component: LogsOnidata,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpOnidataLogsDetails: IRoute = {
  path: ECP.ONIDATA_LOGS_DETAILS.path,
  name: ECP.ONIDATA_LOGS_DETAILS.name,
  icon: HistoryOutlined,
  component: LogsOnidataDetails,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpLogsOnidataQueues: IRoute = {
  path: ECP.LOGS_ONIDATA_QUEUES.path,
  name: ECP.LOGS_ONIDATA_QUEUES.name,
  icon: HistoryOutlined,
  component: LogsOnidataQueues,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpLogsMenu: IRoute = {
  path: ECP.LOGS_MENU.path,
  name: ECP.LOGS_MENU.name,
  icon: HistoryOutlined,
  component: LogsMenu,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpSecurityLogs: IRoute = {

  path: ECP.LOGS_PROPOSAL_ALTERATIONS.path,
  name: ECP.LOGS_PROPOSAL_ALTERATIONS.name,
  icon: HistoryOutlined,
  component: SecurityLogs,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

// PROPOSALS
const ecpProposalFindUserCPF: IRoute = {
  path: ECP.PROPOSAL_FIND_USER_CPF.path,
  name: ECP.PROPOSAL_FIND_USER_CPF.name,
  icon: SolutionOutlined,
  component: ProposalFindUserCPF,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpProposalFindUserSelectCovenant: IRoute = {
  path: ECP.PROPOSAL_FIND_USER_SELECT_COVENANT.path,
  name: ECP.PROPOSAL_FIND_USER_SELECT_COVENANT.name,
  icon: SolutionOutlined,
  component: ProposalFindUserCovenantSelect,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpProposalFindUserInformationConfirm: IRoute = {
  path: ECP.PROPOSAL_FIND_USER_INFORMATION_CONFIRM.path,
  name: ECP.PROPOSAL_FIND_USER_INFORMATION_CONFIRM.name,
  icon: SolutionOutlined,
  component: ProposalFindUserInformationConfirm,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpProposalCreate: IRoute = {
  path: ECP.PROPOSAL_CREATE.path,
  name: ECP.PROPOSAL_CREATE.name,
  icon: SolutionOutlined,
  component: ProposalCreate,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

// CORBANS

// RH

const ecpRh: IRoute = {
  path: ECP.RH.path,
  name: ECP.RH.name,
  icon: AuditOutlined,
  component: RHList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpJuridical: IRoute = {
  path: ECP.JURIDICAL.path,
  name: ECP.JURIDICAL.name,
  icon: AuditOutlined,
  component: Juridical,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

// PARCELS
const ecpParcelAntecipationList: IRoute = {
  path: ECP.PROPOSAL_ANTECIPATION_PARCELS.path,
  name: ECP.PROPOSAL_ANTECIPATION_PARCELS.name,
  icon: AuditOutlined,
  component: ParcelsAntecipationList,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpProposalList: IRoute = {
  path: ECP.PROPOSALS.path,
  name: ECP.PROPOSALS.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpProposalListSearch: IRoute = {
  path: ECP.PROPOSALS_SEARCH.path,
  name: ECP.PROPOSALS_SEARCH.name,
  icon: SolutionOutlined,
  component: ProposalListSearch,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpProposalDetails: IRoute = {
  path: ECP.PROPOSAL_DETAILS.path,
  name: ECP.PROPOSAL_DETAILS.name,
  icon: SolutionOutlined,
  component: ProposalDetails,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpConfiguration: IRoute = {
  path: ECP.CONFIGURATION.path,
  name: ECP.CONFIGURATION.name,
  icon: SettingOutlined,
  component: Configuration,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

// Score
const ecpScore: IRoute = {
  path: ECP.SCORE.path,
  name: ECP.SCORE.name,
  icon: DollarCircleOutlined,
  component: Score,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpScoreForm: IRoute = {
  path: ECP.SCORE_FORM.path,
  name: ECP.SCORE_FORM.name,
  icon: DollarCircleOutlined,
  component: ScoreForm,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpAcceptCredit: IRoute = {
  path: ECP.ACCEPT_CREDIT.path,
  name: ECP.ACCEPT_CREDIT.name,
  icon: AuditOutlined,
  component: AcceptContract,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

// PORTABILITY
const ecpPortabilityFindUserCPF: IRoute = {
  path: ECP.PORTABILITY_FIND_USER_CPF.path,
  name: ECP.PORTABILITY_FIND_USER_CPF.name,
  icon: SolutionOutlined,
  component: PortabilityFindUserCPF,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpPortabilityFindUserInformationConfirm: IRoute = {
  path: ECP.PORTABILITY_FIND_USER_INFORMATION_CONFIRM.path,
  name: ECP.PORTABILITY_FIND_USER_INFORMATION_CONFIRM.name,
  icon: SolutionOutlined,
  component: PortabilityFindUserInformationConfirm,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpPortabilityFindUserSelectCovenant: IRoute = {
  path: ECP.PORTABILITY_FIND_USER_SELECT_COVENANT.path,
  name: ECP.PORTABILITY_FIND_USER_SELECT_COVENANT.name,
  icon: SolutionOutlined,
  component: PortabilityFindUserCovenantSelect,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpPortability: IRoute = {
  path: ECP.PORTABILITY.path,
  name: ECP.PORTABILITY.name,
  icon: RetweetOutlined,
  component: Portability,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpPortabilityCreate: IRoute = {
  path: ECP.PORTABILITY_CREATE.path,
  name: ECP.PORTABILITY_CREATE.name,
  icon: SolutionOutlined,
  component: PortabilityCreate,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpPortabilityAccept: IRoute = {
  path: ECP.PORTABILITY_ACCEPT.path,
  name: ECP.PORTABILITY_ACCEPT.name,
  icon: SolutionOutlined,
  component: PortabilityAccept,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

// RENEGOTIATION

const ecpRenegotiationFindUserCPF: IRoute = {
  path: ECP.RENEGOTIATION_FIND_USER_CPF.path,
  name: ECP.RENEGOTIATION_FIND_USER_CPF.name,
  icon: SolutionOutlined,
  component: RenegotiationFindUserCPF,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRenegociationFindUserInformationConfirm: IRoute = {
  path: ECP.RENEGOCIATION_FIND_USER_INFORMATION_CONFIRM.path,
  name: ECP.RENEGOCIATION_FIND_USER_INFORMATION_CONFIRM.name,
  icon: SolutionOutlined,
  component: RenegotiationFindUserInformationConfirm,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpRenegotiateCredit: IRoute = {
  path: ECP.RENEGOTIATE_CREDIT.path,
  name: ECP.RENEGOTIATE_CREDIT.name,
  icon: FileSyncOutlined,
  component: RenegotiationContracts,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRenegotiationCreate: IRoute = {
  path: ECP.RENEGOTIATION_CREATE.path,
  name: ECP.RENEGOTIATION_CREATE.name,
  icon: SolutionOutlined,
  component: RenegotiationCreate,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpRenegotiationContracts: IRoute = {
  path: ECP.RENEGOTIATION_CONTRACTS.path,
  name: ECP.RENEGOTIATION_CONTRACTS.name,
  icon: SolutionOutlined,
  component: RenegotiationContracts,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpRenegotiationFindUserCovenantSelect: IRoute = {
  path: ECP.RENEGOTIATION_FIND_USER_SELECT_COVENANT.path,
  name: ECP.RENEGOTIATION_FIND_USER_SELECT_COVENANT.name,
  icon: SolutionOutlined,
  component: RenegotiationFindUserCovenantSelect,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

// RH TRANSFERS
const ecpRHTransfers: IRoute = {
  path: ECP.RH_TRANSFER_TO_COMPANY.path,
  name: ECP.RH_TRANSFER_TO_COMPANY.name,
  icon: DollarOutlined,
  component: RHTransfersController,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpRHTransfersDetailing: IRoute = {
  path: ECP.RH_TRANSFER_DETAILING.path,
  name: ECP.RH_TRANSFER_DETAILING.name,
  icon: DollarOutlined,
  component: RHTransferDetailing,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpRHTransfersSelect: IRoute = {
  path: ECP.RH_TRANSFER_SELECT.path,
  name: ECP.RH_TRANSFER_SELECT.name,
  icon: DollarOutlined,
  component: RHTransfersControllerSelect,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHTransfersSelectPrevAllotment: IRoute = {
  path: ECP.RH_TRANSFER_SELECT_PREV_ALLOTMENT.path,
  name: ECP.RH_TRANSFER_SELECT_PREV_ALLOTMENT.name,
  icon: DollarOutlined,
  component: RHTransferPrevAllotmentSelect,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHTransfersMenu = {
  name: 'Repasse',
  icon: DollarOutlined,
  routes: [
    ecpRHTransfersSelect,
    ecpRHTransfersSelectPrevAllotment
  ]
}

// RH ENDORSEMENT

const ecpRHEndorsementDenied: IRoute = {
  path: ECP.RH_ENDORSEMENT_DENIED.path,
  name: ECP.RH_ENDORSEMENT_DENIED.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      search: '?lastStep=averbacao,pagamento',
      pathname: ECP.RH_ENDORSEMENT_DENIED.path
    }
  }
}

const ecpRHEndorsementInCourse: IRoute = {
  path: ECP.RH_ENDORSEMENT_IN_COURSE.path,
  name: ECP.RH_ENDORSEMENT_IN_COURSE.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      search: '?lastStep=averbacao&origin=novo-contrato,renegociacao',
      pathname: ECP.RH_ENDORSEMENT_IN_COURSE.path
    }
  }
}

const ecpRHEndorsementWaiting: IRoute = {
  path: ECP.RH_ENDORSEMENT_WAITING.path,
  name: ECP.RH_ENDORSEMENT_WAITING.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false,
    location: {
      search: '?lastStep=averbacao&origin=portabilidade',
      pathname: ECP.RH_ENDORSEMENT_WAITING.path
    }
  }
}

const ecpRHEndorsementExpired: IRoute = {
  path: ECP.RH_ENDORSEMENT_EXPIRED.path,
  name: ECP.RH_ENDORSEMENT_EXPIRED.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHEndorsementApproved: IRoute = {
  path: ECP.RH_ENDORSEMENT_APPROVED.path,
  name: ECP.RH_ENDORSEMENT_APPROVED.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHEndorsementEffective: IRoute = {
  path: ECP.RH_ENDORSEMENT_EFFETIVE.path,
  name: ECP.RH_ENDORSEMENT_EFFETIVE.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHEndorsementCanceled: IRoute = {
  path: ECP.RH_ENDORSEMENT_CANCELED.path,
  name: ECP.RH_ENDORSEMENT_CANCELED.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHEndorsementClosed: IRoute = {
  path: ECP.RH_ENDORSEMENT_CLOSED.path,
  name: ECP.RH_ENDORSEMENT_CLOSED.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHCreate: IRoute = {
  path: ECP.RH_CREATE.paramLessPath,
  name: ECP.RH_CREATE.name,
  icon: SolutionOutlined,
  component: RHForm,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHEdit: IRoute = {
  path: ECP.RH_EDIT.path,
  name: ECP.RH_EDIT.name,
  icon: SolutionOutlined,
  component: RHForm,
  meta: {
    centered: false,
    hidden: true,
    disabled: false,
    canGoBack: false
  }
}

const ecpRHDetails: IRoute = {
  path: ECP.RH_DETAILS.path,
  name: ECP.RH_DETAILS.name,
  icon: TeamOutlined,
  component: RHDetails,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpRHEndorsementMenu = {
  name: 'Averbação',
  icon: SolutionOutlined,
  routes: [
    ecpRHEndorsementInCourse,
    ecpRHEndorsementWaiting,
    ecpRHEndorsementDenied,
    ecpRHEndorsementExpired,
    ecpRHEndorsementApproved,
    ecpRHEndorsementEffective,
    ecpRHEndorsementCanceled,
    ecpRHEndorsementClosed
  ]
}

const preFormalization: IRoute = {
  path: ECP.PRE_FORMALIZATION.path,
  name: ECP.PRE_FORMALIZATION.name,
  icon: SolutionOutlined,
  component: ProposalList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpJuridicalProposalMenu = {
  name: 'Propostas Juridicos',
  icon: SolutionOutlined,
  routes: [
    preFormalization,
    ecpProposalList
  ]
}

const ecpReports: IRoute = {
  path: ECP.REPORTS.path,
  name: ECP.REPORTS.name,
  icon: SolutionOutlined,
  component: ReportsList,
  meta: {
    centered: false,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpOnidataCredentialsConfiguration: IRoute = {
  path: ECP.ONIDATA_CREDENTIALS.path,
  name: ECP.ONIDATA_CREDENTIALS.name,
  icon: SettingOutlined,
  component: OnidataCredentialsConfig,
  meta: {
    centered: true,
    hidden: false,
    disabled: false,
    canGoBack: false
  }
}

const ecpAccessManagerClient: IRoute = {
  path: ECP.ACCESS_MANAGER_CLIENT.path,
  name: ECP.ACCESS_MANAGER_CLIENT.name,
  icon: SolutionOutlined,
  component: AccessManagerClient,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const ecpAccessManagerBackOffice: IRoute = {
  path: ECP.ACCESS_MANAGER_BACKOFFICE.path,
  name: ECP.ACCESS_MANAGER_BACKOFFICE.name,
  icon: UserSwitchOutlined,
  component: AccessManagerBackOffice,
  meta: {
    centered: false,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const ecpAccessManager = {
  name: 'Gestão de acessos',
  icon: PartitionOutlined,
  routes: [
    ecpAccessManagerClient,
    ecpAccessManagerBackOffice
  ]
}

const suspectBehavior: IRoute = {
  path: ECP.SUSPECT_BEHAVIOR_MENU.path,
  name: ECP.SUSPECT_BEHAVIOR_MENU.name,
  icon: SolutionOutlined,
  component: SuspectBehaviorMenu,
  meta: {
    centered: true,
    hidden: false,
    canGoBack: false,
    disabled: false
  }
}

const suspectBehaviorWhiteList: IRoute = {
  path: ECP.SUSPECT_BEHAVIOR_WHITE_LIST.path,
  name: ECP.SUSPECT_BEHAVIOR_WHITE_LIST.name,
  icon: SolutionOutlined,
  component: WhiteList,
  meta: {
    centered: true,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const suspectBehaviorBanList: IRoute = {
  path: ECP.SUSPECT_BEHAVIOR_BAN_LIST.path,
  name: ECP.SUSPECT_BEHAVIOR_BAN_LIST.name,
  icon: SolutionOutlined,
  component: BanList,
  meta: {
    centered: true,
    hidden: true,
    canGoBack: true,
    disabled: false
  }
}

const accessManagerDetails: IRoute = {
  path: ECP.ACCESS_MANAGER_BACKOFFICE_DETAILS.path,
  name: ECP.ACCESS_MANAGER_BACKOFFICE_DETAILS.name,
  icon: UserSwitchOutlined,
  component: AccessManagerBackOfficeDetails,
  meta: {
    centered: true,
    hidden: true,
    disabled: false,
    canGoBack: true
  }
}

const ecpProposalMenu = {
  name: 'Propostas',
  icon: PartitionOutlined,
  routes: [
    ecpProposalListSearch,
    { ...ecpProposalList, name: 'Visão geral' }
  ]
}

const ecpRoutes = {
  ecpLGPD,

  ecpProposalList,
  ecpProposalListSearch,
  ecpProposalMenu,

  ecpProposalDetails,

  // PROPOSALS
  ecpProposalFindUserCPF,
  ecpProposalFindUserSelectCovenant,
  ecpProposalFindUserInformationConfirm,
  ecpProposalCreate,
  ecpConfiguration,

  // JURIDICAL PROPOSAL
  ecpJuridicalProposalMenu,

  // SUB CORBAN

  ecpParcelAntecipationList,
  ecpClientProposalList,

  // SCORE
  ecpScore,
  ecpScoreForm,

  collaborators,
  teamPromoterIn,

  // RH
  ecpRh,

  ecpRHCreate,
  ecpRHDetails,
  ecpRHEdit,

  ecpJuridical,

  // EGI
  chat,
  listMaster,
  profile,
  ecpAccessManager,
  accessManagerDetails,

  // DISABLED

  portabilityDisabled,
  refinDisabled,

  // acceptCredit
  ecpAcceptCredit,

  // PORTABILITY
  ecpPortabilityFindUserSelectCovenant,
  ecpPortabilityFindUserInformationConfirm,
  ecpPortabilityFindUserCPF,
  ecpPortability,
  ecpPortabilityCreate,
  ecpPortabilityAccept,

  // RENEGOTIATION
  ecpRenegotiationFindUserCPF,
  ecpRenegotiateCredit,
  ecpRenegotiationCreate,
  ecpRenegotiationContracts,
  ecpRenegotiationFindUserCovenantSelect,
  ecpRenegociationFindUserInformationConfirm,

  // Errors
  ecpLogsAPI,
  ecpOnidataLogs,
  ecpOnidataLogsDetails,
  ecpLogsOnidataQueues,
  ecpLogsMenu,
  ecpSecurityLogs,

  // RH TRANSFER TO COMPANY
  ecpRHTransfersMenu,
  ecpRHTransfers,
  ecpRHTransfersDetailing,

  // RH ENDORSEMENT
  ecpRHEndorsementMenu,

  // Reports
  ecpReports,

  // Onidata Credentials
  ecpOnidataCredentialsConfiguration,

  suspectBehavior,
  suspectBehaviorWhiteList,
  suspectBehaviorBanList
}

export type EcpRoutes = typeof ecpRoutes

export {
  ecpRoutes
}
