import React, { useEffect, useMemo } from 'react'
import { Checkbox, Form } from 'antd'
import { useTables } from 'hooks'
import { useDispatch } from 'react-redux'
import { tablesSetFiltersValues, tablesUpdate } from 'store/modules/tables/actions'
import ServerTableFilters from 'components/ServerTable/ServerTableFilters'
import DrawerFilters from 'components/DrawerFilters/DrawerFilters'
import { useForm } from 'antd/lib/form/Form'
import { hasFilterCounter } from 'components/ServerTable/function'

import { formatFieldsForm } from 'utils/utils'

import { useSetFilters } from 'hooks/useSetFilters'
import { PROPOSAL_INITIAL_FILTERS } from 'ecp/app/Proposals/proposalConstants'

import FilterSearchIdentifier from 'components/SearchFilterBySelect/FilterSearchIdentifier'

import ReRequestButton from 'components/ReRequest/ReRequest'

import DrawerFiltersLineCollapsable, { DrawerFiltersLineCollapsableItem } from 'components/DrawerFilters/DrawerFiltersLineCollapsable'
import { DatepickerCustom } from 'components'
import locale from 'antd/lib/date-picker/locale/en_US'
import dayjs, { Dayjs } from 'dayjs'
import { formatDownloadFilters } from '../../views/LogsZip/LogsZipFunctions'
import { CLIENTS_FILTERS_ENTRIES_TO_IGNORE, CLIENTS_FILTERS_KEYS_IGNORE } from '../../logsConstants'

function LogsFiltersDrawerFields () {
  const tables = useTables()

  const disabledDateOf = (date: Dayjs, value: string) => {
    return date.isAfter(dayjs()) || date.isAfter(dayjs(value).endOf('d'))
  }

  const disabledDateTo = (date: Dayjs, value: string) => {
    if (!value) return date.isAfter(dayjs())
    return date.isAfter(dayjs()) || date.isBefore(dayjs(value))
  }

  return (
    <>
      <DrawerFiltersLineCollapsable label='Data do processamento'>
        <DrawerFiltersLineCollapsableItem>
          <Form.Item
            label="De"
            id='startDate'
            name='startDate'
          >
            <DatepickerCustom
              locale={locale}
              format='DD/MM/YYYY'
              disabledDate={date => disabledDateOf(date, tables.filters.data_solicitacao_antes_de)}
            />
          </Form.Item>
        </DrawerFiltersLineCollapsableItem>

        <DrawerFiltersLineCollapsableItem>
          <Form.Item
            label="Até"
            id='endDate'
            name='endDate'
          >
            <DatepickerCustom
              locale={locale}
              format='DD/MM/YYYY'
              disabledDate={date => disabledDateTo(date, tables.filters.data_solicitacao_depois_de)}
            />
          </Form.Item>
        </DrawerFiltersLineCollapsableItem>
      </DrawerFiltersLineCollapsable>
      <Form.Item
        name='status'
        initialValue={undefined}
      >
        <Checkbox.Group>
          <DrawerFiltersLineCollapsable label='Status'>

            <DrawerFiltersLineCollapsableItem key='finished'>
              <Checkbox
                value='finished'
              >
                    Finalizado
              </Checkbox>
            </DrawerFiltersLineCollapsableItem>

            <DrawerFiltersLineCollapsableItem key='error'>
              <Checkbox
                value='error'
              >
                  Erro
              </Checkbox>
            </DrawerFiltersLineCollapsableItem>

          </DrawerFiltersLineCollapsable>
        </Checkbox.Group>
      </Form.Item>

    </>
  )
}

export const identifiers = [
  { label: 'Número da proposta', value: 'proposalNumber' },
  { label: 'Nome de usuário', value: 'userName' },
  { label: 'Departamento', value: 'departmentName' }
]

function LogsZipsFilters () {
  const tables = useTables()
  const dispatch = useDispatch()

  const [form] = useForm()
  const { withOutSearch } = useSetFilters()

  useEffect(() => {
    form.setFieldsValue(tables.filters)
  }, [])

  function clearFilters () {
    form.resetFields()
    dispatch(tablesSetFiltersValues(PROPOSAL_INITIAL_FILTERS))
  }

  const filterCounter = useMemo(() => {
    form.setFieldsValue(formatFieldsForm(tables.filters))

    return Object
      .entries(tables.filters)
      .filter(hasFilterCounter(CLIENTS_FILTERS_KEYS_IGNORE, CLIENTS_FILTERS_ENTRIES_TO_IGNORE))
      .length
  }, [JSON.stringify(tables.filters)])

  return (
    <Form
      layout='vertical'
      form={form}
      onValuesChange={
        (changedValues, allValues) => {
          return withOutSearch(changedValues, formatDownloadFilters(allValues))
        }
      }
    >
      <ServerTableFilters>
        <FilterSearchIdentifier
          placeholder='Escolha o que deseja pesquisar'
          key='identifier'
          label='Pesquisar'
          identifiers={identifiers}
          onReset={() => {
            form.setFieldsValue({ search: '' })
          }}
          onChange={(search, identifier) => {
            dispatch(tablesSetFiltersValues({ ...tables.filters, search, identifier, page: 1 }))
          }}
        />

        <ServerTableFilters>
          <ReRequestButton
            onClick={() => {
              dispatch(tablesUpdate())
            }}
          />

          <DrawerFilters
            onClear={clearFilters}
            counter={filterCounter}
          >
            <LogsFiltersDrawerFields />
          </DrawerFilters>
        </ServerTableFilters>
      </ServerTableFilters>
    </Form>
  )
}

export default LogsZipsFilters
