import { WarningOutlined } from '@ant-design/icons'
import { Form, message } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { Input } from 'components'
import Datepicker from 'components/Datepicker/Datepicker'
import { InputMoney } from 'components/IntlInput/IntlInput'
import Select from 'components/Select/Select'
import { swalError } from 'components/SwalError/SwalError'
import { IError } from 'ecp/app/Proposals/proposalInterfaces'
import LoginRepository from 'ecp/repositories/LoginRepository'
import SignupButton from 'egi/app/Signup/components/SignupButton/SignupButton'
import { SignupTitle } from 'egi/app/Signup/components/SignupTitle/SignupTitle'
import SignupLayout from 'layouts/SignupLayout/SignupLayout'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { UNAUTHS_PATHS } from 'routes/unauthRoutes'
import { handleOnidataErrors } from 'utils/validate'
import { ILGPDAccessSignUp } from '../../LGPDAccessInterfaces'

function LGPDAccessSignUp () {
  const history = useHistory()
  const location = useLocation()
  const formData = new FormData()

  const [form] = useForm<ILGPDAccessSignUp>()
  const [errors, setErrors] = useState<IError<ILGPDAccessSignUp>>({})
  const [loading, setLoading] = useState<boolean>()

  const redirectToNextStep = () => {
    history.push(UNAUTHS_PATHS.LGPD_ACCESS_CONFIRM_SIGN_UP)
  }

  const handleSubmit = async (values: ILGPDAccessSignUp) => {
    setLoading(true)
    try {
      const data = {
        ...values,
        token: location.search.replace('?token=', '')
      }

      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value as string)
      })

      const requestConfig = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }

      await LoginRepository.lgpdSignUp(formData, requestConfig)

      redirectToNextStep()
    } catch (err) {
      if (err?.data && err?.data?.error) {
        setErrors(handleOnidataErrors(err.data.error))
        message.error(err.message)
      }
      swalError({ title: 'Atenção', err, icon: 'warning' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <SignupLayout>
      <SignupTitle className='mb-1'>Cadastro consignado</SignupTitle>
      <div className="lgpd-access__warning p-2">
        <WarningOutlined className="mr-1 lgpd-access__warning-icon" />
          Utilize o <b>último</b> contra-cheque para preencher essas informações
      </div>

      <Form
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
        className='unauth-form'
      >
        <Form.Item
          name='nome'
          label='Nome '
          help={errors?.nome}
          validateStatus={errors?.nome && ('error')}
          rules={[{
            required: true,
            message: 'Digite seu nome'
          }]}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite seu nome...'
          />
        </Form.Item>
        <Form.Item
          name='sobrenome'
          label='Sobrenome'
          help={errors?.sobrenome}
          validateStatus={errors?.sobrenome && ('error')}
          rules={[{
            required: true,
            message: 'Digite seu sobrenome...'
          }]}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite seu sobrenome...'
          />
        </Form.Item>
        <Form.Item
          name='cpf'
          label='CPF '
          help={errors?.cpf}
          validateStatus={errors?.cpf && ('error')}
          rules={[{
            required: true,
            message: 'Digite seu CPF'
          }]}
        >
          <Input
            mask='cpf'
            className='unauth-inputs'
            placeholder='Digite seu CPF...'
          />
        </Form.Item>
        <Form.Item
          name='sexo'
          label='Sexo'
          help={errors?.sexo}
          validateStatus={errors?.sexo && ('error')}
          rules={[{
            required: true,
            message: 'Escolha seu sexo.'
          }]}
        >
          <Select placeholder="Escolha seu sexo..." className='unauth-inputs'>
            <Select.Option value="F">Feminino</Select.Option>
            <Select.Option value="M">Masculino</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='data_nascimento'
          label='Data de nascimento'
          help={errors?.data_nascimento}
          validateStatus={errors?.cpf && ('error')}
          rules={[{
            required: true,
            message: 'Selecione uma data de nascimento'
          }]}
        >
          <Datepicker placeholder="Selecione uma data de nascimento..." className='unauth-inputs' />
        </Form.Item>
        <Form.Item
          name='nome_mae'
          label='Nome da mãe'
          help={errors?.nome_mae}
          validateStatus={errors?.nome_mae && ('error')}
          rules={[{
            required: true,
            message: 'Digite o nome da mãe'
          }]}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite o nome da mãe...'
          />
        </Form.Item>
        <Form.Item
          name='matricula'
          label='Matrícula'
          help={errors?.matricula}
          validateStatus={errors?.matricula && ('error')}
          rules={[{
            required: true,
            message: 'Digite a matricula'
          }]}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite a matricula...'
          />
        </Form.Item>
        <Form.Item
          name='data_admissao'
          label='Data de admissão'
          help={errors?.data_admissao}
          validateStatus={errors?.data_admissao && ('error')}
          rules={[{
            required: true,
            message: 'Selecione uma data de admissão'
          }]}
        >
          <Datepicker placeholder="Selecione uma data de admissão..." className='unauth-inputs' />
        </Form.Item>
        <Form.Item
          name='cargo'
          label='Cargo'
          help={errors?.cargo}
          validateStatus={errors?.cargo && ('error')}
          rules={[{
            required: true,
            message: 'Digite o cargo'
          }]}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite o cargo...'
          />
        </Form.Item>
        <Form.Item
          initialValue={0}
          name='salario'
          label='Salario bruto mensal'
          help={errors?.salario}
          validateStatus={errors?.cargo && ('error')}
          rules={[{
            required: true,
            message: 'Digite o salário'
          }]}
        >
          <InputMoney
            classNameInput='unauth-inputs'
            classNameOnDiv='unauth-inputs'
          />
        </Form.Item>
        <Form.Item
          initialValue={0}
          name='irrf'
          label='IRRF'
          help={errors?.irrf}
          validateStatus={errors?.irrf && ('error')}
          rules={[{
            required: true,
            message: 'Digite o irrf'
          }]}
        >
          <InputMoney
            classNameInput='unauth-inputs'
            classNameOnDiv='unauth-inputs'
          />
        </Form.Item>
        <Form.Item
          initialValue={0}
          name='inss'
          label='INSS'
          help={errors?.inss}
          validateStatus={errors?.inss && ('error')}
          rules={[{
            required: true,
            message: 'Digite o inss'
          }]}
        >
          <InputMoney
            classNameInput='unauth-inputs'
            classNameOnDiv='unauth-inputs'
          />
        </Form.Item>
        <Form.Item
          initialValue={0}
          name='descontos'
          label='Descontos diversos'
          help={errors?.descontos}
          validateStatus={errors?.descontos && ('error')}
          rules={[{
            required: true,
            message: 'Digite os descontos'
          }]}
        >
          <InputMoney
            classNameInput='unauth-inputs'
            classNameOnDiv='unauth-inputs'
          />
        </Form.Item>
        <Form.Item
          initialValue={0}
          name='comprometimento_outros'
          label='Comprometimento em outros (Bancos)'
          help={errors?.comprometimento_outros}
          validateStatus={errors?.comprometimento_outros && ('error')}
        >
          <InputMoney
            classNameInput='unauth-inputs'
            classNameOnDiv='unauth-inputs'
          />
        </Form.Item>
        <Form.Item
          name='email_gestor_imediato'
          label='Email do gestor imediato'
          help={errors?.email_gestor_imediato}
          validateStatus={errors?.email_gestor_imediato && ('error')}
        >
          <Input
            className='unauth-inputs'
            placeholder='Digite o email do gestor...'
          />
        </Form.Item>

        <Form.Item>
          <SignupButton
            loading={loading}
            htmlType='submit'
            className='w-100 mt-4'
          >
            Enviar
          </SignupButton>
        </Form.Item>
      </Form>

    </SignupLayout>

  )
}

export default LGPDAccessSignUp
